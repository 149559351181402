// Bootstrap variables
$link-color: $portal-color;
$primary: #006ce0;
$success: #218739;
$info: #128091;
$warning: #946f00;
$gray-600: #6c7570;
$input-focus-box-shadow: box-shadow-value();

// Custom variables
$container-width: 500px;
$reverse: white;

//Typography
$font-size: (
  h1: 16px,
  content: 14px,
);
