.btn-disable {
  background-color: #777;
  color: white;
  pointer-events: none;
}

a,
.btn-link {
  color: $portal-color;
  &:hover {
    color: $portal-color-dark;
  }
  &:focus,
  &:active {
    color: $portal-color;
  }
}

.link {
  color: $portal-color !important;
  text-decoration: none !important;
  cursor: pointer;
  &:hover {
    color: #003157 !important;
    text-decoration: underline !important;
  }
}

.btn-primary {
  color: #fff;
  background-color: $portal-color;
  border-color: $portal-color;
  &:hover {
    background-color: $portal-color-dark;
    border-color: $portal-color-dark;
  }
  &:focus,
  &:active {
    background-color: $portal-color;
    border-color: $portal-color;
  }
}

.btn-outline-primary {
  color: $portal-color;
  border-color: $portal-color;
  &:focus,
  &:active {
    color: $portal-color;
    border-color: $portal-color;
  }
  &:hover {
    color: #fff;
    background-color: $portal-color-dark;
    border-color: $portal-color-dark;
  }
}
