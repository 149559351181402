.users {
  .large-text {
    font-size: 4em;
    line-height: 1em;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }

  .row-min-height {
    height: 80px;
  }

  button {
    min-width: 150px;
    &.btn-small {
      min-width: 100px;
    }
    &.nav-arrow {
      min-width: 40px;
    }
    &.btn-link {
      &:disabled {
        opacity: 0.3;
      }
    }
    &.sort-by {
      position: relative;
    }
    &.sort-by:before,
    &.sort-by:after {
      border: 5px solid transparent;
      content: "";
      display: block;
      height: 0;
      right: 15px;
      top: 50%;
      position: absolute;
      width: 0;
    }
    &.sort-by:before {
      border-bottom-color: #ccc;
      margin-top: -10px;
    }
    &.sort-by:after {
      border-top-color: #ccc;
      margin-top: 1px;
    }
    &.sort-asc:before {
      border-bottom-color: #000;
    }
    &.sort-dsc:after {
      border-top-color: #000;
    }
  }

  .downloading-survey-details {
    display: inline-block;
  }

  .arrow {
    border: solid $portal-color;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    &.right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    &.left {
      margin-left: 10px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }

  .column-header {
    background: none;
    border: none;
    color: #000;
    padding: 0;
    font-weight: bold;
    text-align: left;
    min-width: 80px !important;

    &:hover {
      text-decoration: none;
      color: #000 !important;
    }
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  .blurred {
    opacity: 0.5;
    filter: blur(10px);
  }

  .blurred:hover {
    opacity: 1;
    filter: blur(0px);
  }
}
