.nav {
  .nav-link {
    &.disabled {
      opacity: 0.5;
    }
    color: white;
  }
}

nav.navbar {
  // This is specifically for the logout button
  button.btn-sm.btn-primary:focus {
    @include focus-box-shadow(white);
  }
}
