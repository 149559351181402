@function box-shadow-value($color: $primary) {
  @return 0 0 0 0.2rem lighten($color, 10%);
}

@mixin focus-box-shadow(
  $color: $primary,
  $border: 1px solid white,
  $important: false
) {
  @if $border != "ignore" {
    border: $border;
  }
  box-shadow: box-shadow-value($color) #{if($important == true, !important, "")};
}
